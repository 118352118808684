@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	textarea:-webkit-autofill,
	textarea:-webkit-autofill:hover,
	textarea:-webkit-autofill:focus,
	select:-webkit-autofill,
	select:-webkit-autofill:hover,
	select:-webkit-autofill:focus {
		-webkit-box-shadow: 0 0 0px 1000px #fff inset;
		transition: background-color 5000s ease-in-out 0s;
	}

	input[class="form-input"]:-webkit-autofill,
	input[class="form-input"]:-webkit-autofill:hover,
	input[class="form-input"]:-webkit-autofill:focus,
	textarea[class="form-input"]:-webkit-autofill,
	textarea[class="form-input"]:-webkit-autofill:hover,
	textarea[class="form-input"]:-webkit-autofill:focus,
	select[class="form-input"]:-webkit-autofill,
	select[class="form-input"]:-webkit-autofill:hover,
	select[class="form-input"]:-webkit-autofill:focus {
		@apply form-input;
	}

	input[class="form-input-icon"]:-webkit-autofill,
	input[class="form-input-icon"]:-webkit-autofill:hover,
	input[class="form-input-icon"]:-webkit-autofill:focus,
	textarea[class="form-input-icon"]:-webkit-autofill,
	textarea[class="form-input-icon"]:-webkit-autofill:hover,
	textarea[class="form-input-icon"]:-webkit-autofill:focus,
	select[class="form-input-icon"]:-webkit-autofill,
	select[class="form-input-icon"]:-webkit-autofill:hover,
	select[class="form-input-icon"]:-webkit-autofill:focus {
		@apply form-input-icon;
	}
}

@layer utilities {
	.form-group {
		@apply mb-5 space-y-1;
	}

	.form-group label {
		@apply block text-sm;
	}

	.form-input-required {
		@apply ml-1 text-red-500;
	}

	.form-input {
		@apply w-full h-10 px-4 py-1 text-sm border !border-gray-300 rounded focus:!outline-none focus:!ring-2 focus:!border-transparent;
	}

	.form-input.disabled,
	.form-input[disabled],
	.form-input-textarea[disabled] {
		@apply bg-gray-200 opacity-100;
	}

	.form-input-sm {
		@apply form-input;
		@apply h-7 text-sm;
	}

	.form-group-icon {
		@apply w-full h-10 text-sm bg-white border border-gray-300 rounded ring-2 ring-transparent;
		@apply flex overflow-hidden;
	}

	.form-group-icon:focus-within {
		@apply border-transparent outline-none;
	}

	.form-group-icon > label {
		@apply w-10 h-full flex flex-shrink-0;
	}

	.form-group-icon > label > .form-group-icon-attach {
		@apply m-auto;
	}

	.form-input-icon {
		@apply w-full h-9 px-0 py-1 text-sm border-none outline-none focus:border-transparent focus:outline-none focus:ring-0;
	}

	.text-fade {
		@apply text-gray-500;
	}

	.btn {
		@apply w-max flex items-center justify-center font-bold space-x-2 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-opacity-75 cursor-pointer;
	}

	.btn.disabled,
	.btn[disabled] {
		@apply cursor-not-allowed;
		opacity: 0.3;
		filter: opacity(30);
	}

	.btn-disabled-30.disabled,
    .btn-disabled-30[disabled] {
        @apply cursor-not-allowed;
        opacity: 0.3 !important;
        filter: opacity(30) !important;
    }

	.btn > * {
		@apply my-auto;
	}

	.btn-sm {
		@apply h-7 px-3 py-1 text-xs;
	}

	.btn-md {
		@apply h-9 px-4 text-xs;
	}

	.btn-lg {
		@apply px-8 py-3;
	}

	.btn-block {
		@apply w-full;
	}

	.btn-transparent-black {
		@apply border border-gray-400;
	}

	.form-input-message {
		@apply capitalize-first-letter text-sm text-red-500 font-medium;
	}

	.form-input-checkbox {
		/* @apply appearance-none text-ep-yellow bg-white border-2 border-gray-300 rounded cursor-pointer; */
		@apply appearance-none bg-white border-2 border-gray-300 rounded cursor-pointer;
	}

	.animate {
		@apply transform transition duration-500 ease-in-out;
	}

	.react-slideshow-container + ul.indicators .each-slideshow-indicator:before {
		background: #fff;
	}

	.modal-close {
		@apply w-8 h-8 flex items-center justify-center hover:bg-red-400 rounded cursor-pointer;
	}

	.sidebar-links-divider {
		@apply mt-6 mb-1 opacity-75 font-bold uppercase px-6 text-white;
		font-size: 11px;
	}

	.sidebar-links {
		@apply h-10 pr-3 flex text-white text-sm font-semibold items-center cursor-pointer;
	}

	.sidebar-links-highlight {
		@apply w-0.5 h-6 mr-6 bg-white invisible;
	}

	.sidebar-links.active .sidebar-links-highlight {
		/* @apply visible; */
	}

	.sidebar-links-icon {
		@apply !w-4 !h-4 mr-5 object-contain;
	}

	.object-cover,
	.object-contain {
		@apply w-full h-full;
	}

	.text-fade {
		@apply text-gray-400;
	}

	.container {
		@apply mx-auto px-4 xl:px-10;
	}

	/* 
	.dropdown {
		@apply z-10 bg-white absolute rounded shadow-lg overflow-hidden;
		min-width: 100px;
	}

	.dropdown-left {
		@apply left-0;
	}

	.dropdown-right {
		@apply right-0;
	}

	.dropdown-item {
		@apply px-4 py-1.5 text-sm block border-t border-gray-100 cursor-pointer hover:text-white hover:bg-ep-blue;
	}

	.dropdown > .dropdown-item:nth-child(1) {
		@apply border-t-0;
	}
	*/

	.dropdown {
		@apply z-10 bg-white absolute rounded-md outline-none overflow-hidden;
		box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
		min-width: 100px;
		max-width: 200px;
		/* width: 150px; */
	}

	.dropdown-content {
		/* @apply m-3; */
	}

	.dropdown-left {
		@apply left-0;
	}

	.dropdown-right {
		@apply right-0;
	}

	.dropdown-item {
		@apply px-3 py-2.5 text-sm text-left block rounded-lg cursor-pointer;
		@apply text-[#505666] hover:bg-[#F4F4F4];
		/* @apply hover:bg-gray-200; */
	}

	.dropdown > .dropdown-item:nth-child(1) {
		@apply border-t-0;
	}

	.dropdown-item-icon {
		@apply flex justify-start;
	}

	.dropdown-item-icon > *:nth-child(1) {
		@apply w-5 mr-4 h-full;
	}

	.dropdown-item-user-header {
		@apply dropdown-item;
		@apply px-5 py-3 flex items-center !rounded-none !important;
	}

	.dropdown-item-user-header:hover .svg-fill-primary,
	.dropdown-item-user-header:hover .svg-fill-primary * {
		/* color: #fff !important; */
		/* fill: #fff !important; */
	}

	.box-border-only {
		@apply bg-white rounded-md border;
		/* border-color: #9CA3AF; */
	}

	.box-default-overflow {
		@apply p-4 xl:p-6 box-border-only;
	}

	.box {
		@apply box-default-overflow overflow-hidden;
	}

	.box-no-padding {
		@apply p-0 xl:p-0;
	}

	.label {
		@apply w-max px-3 py-0.5 flex justify-center items-center rounded-full whitespace-nowrap font-bold;
		font-size: 11px;
	}

	.label-sm {
		@apply !px-3 !py-0.5;
		font-size: 10px !important;
	}

	.label-blue {
		@apply bg-blue-200;
	}

	.label-green {
		@apply bg-green-200;
	}

	.label-red {
		@apply bg-red-200;
	}

	.label-gray {
		@apply bg-gray-200;
	}

	.label-yellow {
		/* @apply bg-yellow-200; */
		background: #fffcda;
	}

	.table-container {
		@apply w-full overflow-x-auto overflow-y-hidden;
	}

	.table-container > .table th,
	.table-container > .table td {
		/* @apply w-min whitespace-nowrap; */
		@apply w-min;
	}

	.table {
		@apply w-full min-w-min rounded-md overflow-hidden;
		border-collapse: separate;
		/* border-spacing: 0 12px; */
		border-spacing: 0 0;
	}

	.table thead {
		@apply bg-fade-blue;
		/* @apply border-b; */
	}

	.table thead th {
		@apply p-4 text-sm text-left font-semibold;
		color: #8083a3;
	}

	.table tbody {
		@apply bg-white;
	}

	.table tbody tr {
		/* @apply border-t border-b border-black !important; */
	}

	.table tbody tr td {
		@apply p-4 text-sm text-left;
		@apply border-b border-gray-200;
		/* max-width: 300px; */
		min-width: 100px;
	}

	.table.table-border tbody tr.table-row-space {
		@apply h-3 invisible !important;
	}

	.table.table-border tbody > tr > td {
		/* @apply border-t border-b border-gray-200 overflow-hidden !important; */
	}

	.table.table-rounded tbody > tr > td:first-child {
		/* @apply border-l rounded-tl-lg rounded-bl-lg; */
	}

	.table.table-rounded tbody > tr > td:last-child {
		/* @apply border-r rounded-tr-lg rounded-br-lg; */
	}

	.table.table-align-top td {
		@apply align-top;
	}

	.table td .dropdown {
		@apply right-12 sm:right-16;
	}

	.table td .table-dropdown-align-top {
		@apply -mt-2;
	}

	.table td .dropdown {
		/* @apply -ml-3 md:-ml-4; */
	}

	.table td .dropdownNew {
		@apply -mt-px;
	}

	.table-info-full-page {
		@apply box;
		@apply py-24 mt-6;
	}

	.table-info {
		@apply px-2 py-20 mt-3 text-center text-gray-500;
	}

	.table-info > svg {
		@apply text-4xl mb-2;
	}

	.pagination {
		@apply flex rounded space-x-0.5 overflow-hidden;
	}

	.pagination-items {
		@apply h-8 px-4 text-xs flex space-x-2 rounded-md cursor-pointer hover:bg-gray-200;
		@apply mr-1 !important;
	}

	.pagination-items > div {
		@apply my-auto;
	}

	.pagination-items-icon {
		@apply w-4 h-3 m-auto;
	}

	.pagination-items.disabled {
		@apply bg-gray-100 cursor-default hover:bg-gray-100;
	}

	.pagination-items-icon.disabled,
	.pagination-items-icon.disabled > div {
		@apply text-gray-300 group-hover:text-gray-300;
	}

	.pagination-items-active {
		@apply text-white bg-ep-blue hover:bg-ep-blue;
	}

	.tab-pill {
		@apply px-5 sm:px-10 py-1 m-auto text-sm rounded-lg hover:text-white hover:bg-ep-blue;
	}

	.tab-pill-active {
		@apply bg-white shadow;
	}

	.ellipsis {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}

	.ellipsis-2-lines,
	.ellipsis-two-lines,
	.ellipsis-line-2,
	.ellipsis-line-two,
	.ellipsis-lines-2,
	.ellipsis-lines-two {
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}

	.tab {
		@apply py-3 text-sm font-bold text-gray-500 border-b-2 hover:bg-gray-100;
	}

	.tab.active {
		@apply text-ep-blue bg-green-100 border-ep-blue;
	}

	.tab-design-2 {
		@apply flex border border-gray-300 rounded-md overflow-hidden;
	}

	.tab-design-2 > div {
		@apply px-3 py-1 text-sm border-r border-gray-300 font-bold cursor-pointer;
	}

	.tab-design-2 > div:last-child {
		@apply border-r-0;
	}

	.tab-design-2 > div:hover {
		@apply bg-gray-200;
	}

	.tab-design-2 > div.active {
		@apply text-white bg-ep-blue;
	}

	.note {
		@apply text-sm;
		padding: 5px 10px;
		text-align: justify;
		background: #ffea77 !important;
	}

	.floated-content:after {
		display: block;
		content: "";
		clear: both;
	}

	#headlessui-menu-items-2 {
		outline: none !important;
	}

	/* .bg-gray {
		background: #f9fafb;
	} */

	.bg-fade-blue,
	.bg-blue-fade {
		/* background: #d9e3ff; */
		background: #E5FDFF;
	}

	.react-confirm-alert-overlay {
		background: rgba(255, 255, 255, 0.77);
	}

	.capitalize-first-letter:first-letter {
		@apply uppercase;
	}

	.bg-blur {
		-webkit-backdrop-filter: blur(4px);
		backdrop-filter: blur(4px);
		/* background-color: rgba(255, 255, 255, 0.5); */
	}
	
	.platform-fee-modal-title {
		@apply p-5 w-full sticky top-0 left-0 z-10 bg-white;
	}
	
	.platform-fee-modal-content {
		@apply pb-8 pt-3 px-5 max-h-96;
	}
	
	.platform-fee-modal-actions {
		@apply flex items-center justify-end pt-3 pb-6 lg:pb-3;
	}
	
	#number-input::-webkit-outer-spin-button,
	#number-input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

}

body > iframe[style*="2147483647"]:not([id="webpack-dev-server-client-overlay"]) {
	display: none !important;
}
