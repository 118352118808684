@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
	/* @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap"); */
	@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;600;700&display=swap");

	/* 
    @font-face {
        font-family: "DM Sans";
        font-weight: 400;
        src: local("DM Sans"),
        url("../fonts/dmsans/DMSans-Regular.ttf") format("truetype");
    }
    */

	@font-face {
		font-family: "Boing";
		font-weight: 400;
		src: local("Boing"),
			url("../fonts/Boing/Boing-Regular.ttf") format("truetype");
	}

	@font-face {
		font-family: "Boing";
		font-weight: 600;
		src: local("Boing"),
			url("../fonts/Boing/Boing-Semibold.ttf") format("truetype");
	}

	@font-face {
		font-family: "Boing";
		font-weight: 700;
		src: local("Boing"), url("../fonts/Boing/Boing-Bold.ttf") format("truetype");
	}

	@font-face {
		font-family: "Recoleta";
		font-weight: 400;
		src: local("Recoleta"),
			url("../fonts/Recoleta/Recoleta-RegularDEMO.ttf") format("truetype");
	}

	@font-face {
		font-family: "Recoleta";
		font-weight: 700;
		src: local("Recoleta"),
			url("../fonts/Recoleta/Recoleta-Bold.ttf") format("truetype");
	}

	@font-face {
		font-family: "Aeonik";
		font-weight: 400;
		src: local("Aeonik"), url("../fonts/Aeonik/Aeonik-Regular.ttf") format("truetype");
	}

	@font-face {
		font-family: "Aeonik";
		font-weight: 500;
		src: local("Aeonik"), url("../fonts/Aeonik/Aeonik-Medium.ttf") format("truetype");
	}

	@font-face {
		font-family: "Aeonik";
		font-weight: 700;
		src: local("Aeonik"), url("../fonts/Aeonik/Aeonik-Bold.ttf") format("truetype");
	}

	body {
		color: #0d152e;
		/* font-size: 16px; */
		font-weight: normal;
		/* font-family: "DM Sans", sans-serif; */
		font-family: "Aeonik";
	}

	button.earni-button:disabled {
		background: lightgray !important;
		cursor: not-allowed;
		background-opacity: 0.5;
	}

	input.form-input #payroll-fee-inputsearch_input:focus {
		border: 1px solid red !important;
		outline: none !important;
	}
}

.input-range-wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.input-range-div {
	width: 100%;
	max-width: 45%;
}

.input-range-div input {
	height: 30px;
}

.input-range-div input::placeholder {
	font-size: 11px;
}

@media screen and (min-width: 250px) {
	.settings-form-wrap {
		width: 100%;
	}
}

		@media screen and (min-width: 768px) {
		.settings-form-wrap {
		width: 45%;
		}
	}


@layer utilities {
	.font-tiny {
		font-size: 10px;
	}

	.font-boing {
		font-family: "Boing";
	}

	.font-work-sans {
		font-family: "Work Sans", sans-serif;
	}

	.font-recoleta {
		/* font-family: "Recoleta"; */
	}

	.auth-bg-ep-pattern {
		background-image: url("../images/bgs/auth-bg-ep-pattern.png");
	}

	.header-links {
		@apply h-full px-2 font-bold text-xs flex items-center;
		@apply header-hover-border-b;
	}

	.settings-form-container {
		@apply w-full flex items-center justify-center flex-col md:flex-row 
			md:justify-between flex-wrap
	}

	.header-hover-border-b {
		@apply border-t-4 border-b-4 border-transparent;
	}

	.header-hover-border-b:hover {
		border-bottom-color: .form-input #payroll-fee-input416ff4;
	}

	.header-links.active {
		@apply text-ep-blue;
	}

	.sidebar-bg-ep-pattern {
		/* background-image: url("../images/bgs/sidebar-bg-ep-pattern.png"); */
	}

	@media screen and (min-height: 650px) {
		.sidebar-body {
			@apply pb-64;
		}
		.sidebar-footer {
			@apply absolute bottom-0;
		}
		.sidebar-footer-secondary {
			@apply hidden;
		}
	}

	.form-input {
		@apply focus:ring-ep-primary;
	}

	::-webkit-file-upload-button {
		@apply bg-ep-blue-deeper text-white py-1 px-2 border-0;
	}

	.form-group-icon:focus-within {
		@apply ring-ep-primary;
	}

	.btn-ep-primary {
		@apply text-white bg-ep-primary border-green-700 focus:ring-green-700;
		/* @apply text-white border-green-700 focus:ring-green-700; */
		/* background-color: #1544CE; */
	}

	.btn-ep-blue {
		@apply text-white bg-ep-blue-deeper focus:ring-ep-primary;
	}

	.btn-blue-hover:hover {
		@apply hover:bg-ep-blue hover:text-white;
	}

	.btn-gray-hover:hover {
		@apply hover:bg-gray-200 !important;
	}

	.btn-blue-outline {
		@apply focus:ring-ep-primary;
	}

	.btn-ep-yellow {
		@apply text-white bg-ep-yellow focus:ring-yellow-400;
	}

	.btn-white {
		@apply bg-white border border-gray-400;
	}

	.btn-green {
		@apply text-white;
		background: .form-input #payroll-fee-input66cb9f;
	}

	.btn-red {
		@apply text-white bg-red-400 focus:ring-red-400;
	}

	.btn-transparent-black {
		@apply border border-gray-400;
		@apply focus:ring-gray-400;
	}

	.btn-transparent-primary {
		@apply text-ep-primary border border-ep-primary;
		@apply focus:ring-ep-primary;
	}

	.sidebar-links {
		@apply opacity-50 bg-black bg-opacity-0;
		/* @apply hover:bg-ep-blue-deeper; */
		@apply hover:bg-opacity-20;
	}

	.sidebar-links.active {
		@apply opacity-100 bg-ep-blue-deeper border-t border-b border-white;
	}

	.sidebar-links .sidebar-links-icon {
		/* @apply opacity-40; */
		@apply svg-fill-white;
	}

	.sidebar-links.active .sidebar-links-icon {
		/* @apply opacity-100; */
	}

	.page-title {
		@apply font-bold text-2xl sm:text-3xl font-recoleta;
	}

	.page-title-mini {
		@apply font-semibold text-lg sm:text-xl font-recoleta;
	}

	.page-nav {
		@apply h-full flex px-2 sm:px-4 hover:bg-gray-200;
	}

	.page-nav > div {
		@apply text-sm font-medium my-auto opacity-50;
	}

	.page-nav.active > div {
		@apply font-bold opacity-100;
	}

	.page-nav-blue {
		@apply h-full text-sm font-medium flex items-center px-10 border-b-2 border-transparent;
	}

	.page-nav-blue.active {
		@apply text-ep-blue border-ep-blue;
		/* @apply bg-blue-100; */
		background: #E5FDFF;
	}

	.svg-fill-white,
	.svg-fill-white * {
		color: #fff !important;
		fill: #fff !important;
	}

	.svg-fill-black,
	.svg-fill-black * {
		color: #000 !important;
		fill: #000 !important;
	}

	.svg-fill-orange,
	.svg-fill-orange * {
		color: #CEB23C !important;
		fill: #CEB23C !important;
	}

	.svg-fill-ccc,
	.svg-fill-ccc * {
		color: #ccc !important;
		fill: #ccc !important;
	}

	.svg-fill-red,
	.svg-fill-red * {
		color: #f00 !important;
		fill: #f00 !important;
	}

	.svg-fill-inherit,
	.svg-fill-inherit * {
		color: inherit !important;
		fill: inherit !important;
	}

	.svg-fill-primary,
	.svg-fill-primary *,
	.sidebar-links.active .svg-fill-white,
	.sidebar-links.active .svg-fill-white * {
		color: #1638ce !important;
		fill: #1638ce !important;
	}

	.svg-stroke-white,
	.svg-outline-white,
	.svg-stroke-white *,
	.svg-outline-white * {
		color: transparent !important;
		fill: transparent !important;
		stroke: #fff !important;
	}

	.svg-stroke-black,
	.svg-outline-black,
	.svg-stroke-black *,
	.svg-outline-black * {
		color: transparent !important;
		fill: transparent !important;
		stroke: #000 !important;
	}

	.svg-stroke-dark,
	.svg-outline-dark,
	.svg-stroke-dark *,
	.svg-outline-dark * {
		color: transparent !important;
		fill: transparent !important;
		stroke: #28303f !important;
	}

	.svg-stroke-aaa,
	.svg-outline-aaa,
	.svg-stroke-aaa *,
	.svg-outline-aaa * {
		color: transparent !important;
		fill: transparent !important;
		stroke: #aaa !important;
	}

	.svg-stroke-primary,
	.svg-outline-primary,
	.svg-stroke-primary *,
	.svg-outline-primary *,
	.sidebar-links.active .svg-stroke-white,
	.sidebar-links.active .svg-outline-white,
	.sidebar-links.active .svg-stroke-white *,
	.sidebar-links.active .svg-outline-white * {
		color: transparent !important;
		fill: transparent !important;
		stroke: #1638ce !important;
	}

	.icon-fade {
		color: .form-input #payroll-fee-input8083a3;
	}

	.tab-selector{
        @apply py-3 px-3 sm:px-5 text-sm hover:bg-gray-100 cursor-pointer;
    }

    .tab-selector.active {
        @apply text-ep-blue border-b-4 border-ep-blue;
    }

	/* 
    div[aria-roledescription="carousel"]{
        @apply relative bg-blue-300;
    }
    */

	.react-slideshow-container + ul.indicators {
		@apply justify-start;
		/* @apply bg-green-300 absolute; */
		/* top: 78%; */
	}

	.react-slideshow-container + ul.indicators li {
		@apply w-auto h-2 p-0 m-0 mr-2 bg-red-300 inline-block rounded-full overflow-hidden !important;
	}

	.react-slideshow-container + ul.indicators .each-slideshow-indicator {
		@apply w-6 h-full m-0 opacity-100 bg-green-300 !important;
	}

	.react-slideshow-container + ul.indicators .each-slideshow-indicator.active {
		@apply w-14 !important;
	}

	.react-slideshow-container + ul.indicators .each-slideshow-indicator:before {
		@apply w-full h-full rounded-none !important;
		background: .form-input #payroll-fee-input9ca3af !important;
	}

	.react-slideshow-container
		+ ul.indicators
		.each-slideshow-indicator.active:before {
		/* @apply w-14 bg-ep-blue !important; */
		@apply bg-ep-blue-deeper !important;
	}

	.settings-tab a {
		@apply text-gray-500 hover:text-ep-blue;
	}

	.settings-tab-mobile a {
		@apply whitespace-nowrap;
	}

	.settings-tab a.active {
		@apply font-bold text-black;
	}
	
	.payroll-setting-wrapper {
		@apply w-full flex items-start justify-between flex-wrap lg:flex-nowrap mt-6 border-b border-gray-100;
	}
	
	.payroll-setting-service {
		@apply w-full lg:w-1/5 mb-4 lg:mb-0;
	}
	
	.payroll-setting-form-wrapper {
		@apply w-full lg:w-4/5 flex items-center flex-col;
	}
	
	.payroll-setting-form {
		@apply w-full flex items-center mb-3 flex-wrap lg:flex-nowrap;
	}
	
	.payroll-setting-form-item {
		@apply w-full lg:w-2/4;
	}
	
	#platform-fee-input {
		@apply pl-8;
	}
	
	#platform-fee-input::-webkit-outer-spin-button,
	#platform-fee-input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
	}
	
	#number-input::-webkit-outer-spin-button,
	#number-input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
	}
	
	.platform-fee-currency {
		@apply absolute top-2 left-3;
	}

	.form-checkbox {
		@apply appearance-none border cursor-pointer overflow-hidden !important;
		@apply rounded-[4px] !important;
		@apply !text-gray-300 !border-gray-300 !ring-ep-primary;
	}

	.form-checkbox:not(.disabled):checked {
		@apply text-ep-primary border-ep-primary ring-ep-primary !important;
	}

	.form-checkbox:disabled {
		cursor: not-allowed !important;
		background-color: #eceef1;
	}
}
